function mobileMenu() {

    const $toggle = document.getElementById( "nav-toggle" );
    const $menu = document.getElementById ( "nav-menu" );
    const $logo = document.getElementsByClassName ( "nav__logo" )[0];

    $toggle.addEventListener( "click", function() {
        if( $toggle.classList.contains( "nav__toggle--inactive" ) ) {
            
            $toggle.classList.replace( "nav__toggle--inactive", 'nav__toggle--active' );
            $menu.classList.replace( "nav__menu--inactive", 'nav__menu--active' );
            $logo.classList.add( "nav__logo--active" );

        } else {

            $toggle.classList.replace( "nav__toggle--active", 'nav__toggle--inactive' );
            $menu.classList.replace( "nav__menu--active", 'nav__menu--inactive' );
            $logo.classList.remove( "nav__logo--active" );

        }
    });
}

mobileMenu();


// Fixed navbar on scroll

window.onscroll = function() {scroll()};

const header = document.getElementsByClassName( 'nav' )[0];

let sticky = header.offsetTop;

function scroll() {
    if (window.pageYOffset > sticky) {
        header.classList.add('nav--sticky');
    } else {
        header.classList.remove('nav--sticky');
    }
}